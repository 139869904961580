/* font-family: 'Playfair Display', serif;
font-family: 'Roboto', sans-serif; */
body{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.1875;
  color: #191919;
  background-color: #FAF9F3;
}
.page-header{
  background-color: #FAF9F3;

}
.font-135{
  font-size: 135px;
  line-height: 84px;
}
.font-24{
  font-size: 24px;
  line-height: 1.2;
}
.font-22{
  font-size: 22px;
  line-height: 1.132;
}
.font-secondary{
  font-family: 'Playfair Display', serif;
}
.font-45{
  font-size: 45px;
  line-height: 1.333;
}
.font-16{
  font-size: 16px;
  line-height: 19px;
}
.font-32{
  font-size: 32px;
  line-height: 1.1875;
}

.container{
  max-width: 960px;
  padding-left: 22px;
  padding-right: 22px;
}
.object-fit{
  object-fit: cover;
  object-position: top;
}

/* Starts css for Header */
.page-header .nav-link{
  color: #000000;
  padding: 22px;
}
.page-header li{
  margin: 0 40px;
}
.block > *:last-child{
  margin-bottom: 0;
}
.form-block{
  max-width: 550px;
}
.form-control{
  border: 1px solid #000 !important;
  height: 71px;
  font-size: 24px;
  padding: 20px 240px 20px 30px;
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.5) !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.5) !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0, 0, 0, 0.5) !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(0, 0, 0, 0.5) !important;
}
.form-button{
  top: 12px;
  right: 13px;
}
.btn{
  padding: 6px 28px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 21px;

}
.btn.btn-link{
  padding: 0;
}
.footer-link{
  display: flex;
  align-items: center;
}
.footer-link:not(:last-child)::after{
  content: '';
  display: block;
  clear: both;
  height: 36px;
  width: 1px;
  margin: 0 14px;
  align-self: center;
  background-color: #000;
}
.footer-link:not(:first-child){
  margin-left: 20px;
}
.modal-lg{
  max-width: 926px;
  width: 93%;
  margin: 20px auto;
}
.modal-content{
  background: #191919;
  padding: 0 10px 10px;

}
.btn-close{
  position: absolute;
  filter: invert(100);
  opacity: 1;
  right: 30px;
  top: 25px;
}
.modal-header{
  border: 0;
  padding-bottom: 20px;
}
.modal-header p{
  margin-bottom: 0;
}
.block > *:last-child{
  margin-bottom: 0;
}
.slick-next::before,
.slick-prev::before{
  display: none;
}
.slick-arrow{
  width: 20px;
  height: 20px;
}
.slick-prev{
  background: url('../public/assets/img/left-arrow.svg') no-repeat center !important;
  background-size: contain !important;
}
.slick-next{
  background: url('../public/assets/img/right-arrow.svg') no-repeat center !important;
  background-size: contain !important;
}
.modal-footer{
  border: 0;
}
.modal-footer .form-control{
  border: 1px solid #fff !important;
  color: #fff !important;
  font-size: 16px !important;
}
.block-box{
  border: 3px solid transparent;
}
.pointer{
  cursor: pointer;
}
.active{
 
border: 3px solid #F00;
}
.modal-footer  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5) !important;
}
.custum-place ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5) !important;
}
.modal-footer ::-moz-placeholder { /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5) !important;
}
.modal-footer :-ms-input-placeholder { /* IE 10+ */
  color: rgba(255, 255, 255, 0.5) !important;
}
.modal-footer :-moz-placeholder { /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5) !important;
}
.slick-track{
  display: flex;
}
.slick-slide{
  padding: 0 10px;
 
  height: 435px;
}
.slick-slide > div{
  position: relative;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.card-block{
  right: 0;
  left: 0;
  padding: 32px;
  text-align: center;
}
.card-block::after{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 325px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 50.77%);
  z-index: 0;
}
img{
  max-width: 100%;
  max-height: 100%;

}
.bg-gredient:after{
    content:'';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(25, 25, 25, 0.00) 60.09%, #191919 98.02%);
}
.card-body,
.card-footer{
  position: relative;
  z-index: 1;
}
.btn-white{
  border-radius: 9px;
  background: #FFF !important;
  color: #000 !important;
}
.btn-white:hover{
  opacity: 0.8;
}

.btn-back{
  border-radius: 9px;
  background: #191919 !important;
  color: #FFF !important;
}
.btn-white:hover{
  opacity: 0.8;
}
.slick-slider{
  max-width: 880px;
  margin: 0 auto;
}
.slick-dots li button{
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  background-color: transparent;
  border-radius: 50%;
}
.slick-dots li button::before{
  display: none;
}
.link-hover{
  transition: all 0.3s ease;
  border-radius: 5px;
}
.link-hover:hover{
  /* background: #000;
  color: #fff; */
  padding-bottom: 10px;
}
.slick-dots{
  bottom: -52px;
}
.slick-dots li.slick-active button{
  background-color: #000;
}

.long-text-box{
  min-width: 500px;
}
.min-height {
  min-height: 468px !important;
  max-height: 468px !important;
}
.banner-image{
  height: 522px;
}
.w-66{
  width: 66px;
  height: 66px;
}
.mb-38{
  margin-bottom: 38px;
}
.py-120{
  padding-top: 120px;
  padding-bottom: 120px;
}
.pb-120{
  padding-bottom: 120px;
}
.mt-10 {
  margin-top: 10px;
}
.play-btn{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
}
@media screen and (max-width:991.98px) {
  .page-header li{
    margin: 0 20px; 
  }
  .page-header .nav-link{
    padding: 12px;
  }
  .font-135{
    font-size: 85px;
    line-height: 40px;
  }
  .font-45{
    font-size: 26px;
  }
  .mobile-font-light{
    font-weight: 300;
  }
  .form-control{
    height: 50px;
    padding: 12px 170px 12px 20px;
    font-size: 18px;

  }
  .mobile-pb-80{
    padding-bottom: 80px;
  }
 
  .mobile-mb-20{
    margin-bottom: 20px;
  }
  .mt-10 {
    margin-top: 8px;
  }
  .font-16{
    font-size: 12px;
    line-height: normal;
  }
  .form-button{
    top: 5px;
    right: 5px;

  }
  .btn{
    padding: 8px 9px;
    font-size: 14px;
  }
  .header{
    padding: 20px 22px;
  }
  .py-120{
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pb-120{
    padding-bottom: 80px;
  }
  .font-32{
    font-size: 18px;
  }
  .font-22{
    font-size: 12px;
  }
  .font-24{
    font-size: 18px;
  }
  .mb-38 {
    margin-bottom: 20px;
}
body{
  font-size: 12px;
}
 .mobile-font-16{
    font-size: 16px;
  }
  .mobile-pt-20{
    padding-top: 20px;
  }
  .slick-arrow{
    top: auto;
    transform: none;
    bottom:0;
    left: auto;
    width: 20px;
    height: 20px;
    z-index: 99;
  }
  .slick-dots{
    bottom: 2px;
  }
  
  .card-block::after{
    height: 110%;
  }
  .card-block{
    padding: 10px;
  }
  .slick-slide{
    padding: 0 5px;
    height: 246px;
  }
  .slider-block{
    padding-bottom: 35px;
  }
  .slick-next{
    right: 0;
  }
  .slick-prev{
    right: 30px;
  }
  .slick-dots li{
    width: 12px;
    height: 12px;
  }
  .slick-dots li button{
    width: 10px;
    height: 10px;
  }

}
@media screen and (max-width:767.98px) {
  .mobile-relative{
    position: relative !important;
    
  }
  .bg-gredient:after{
    display: none;
  }
  .mobile-pt-25{
    padding-top: 25px;
  }

  .blink{
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
    position: absolute;
  }
  .mobile-p-10{
    padding: 10px;
  }
  .mobile-card::after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 130%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 23.77%);
    z-index: 0;
  }
  .mobile-card > *{
    position: relative;
    z-index: 1;
  }
  .slick-list{
    margin: 0 -25px 0 -5px;
  }
  .slidebox{
    width: 144px !important;
  }
  .slidebox img{
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .page-header .nav-link{
    padding: 12px 6px;
    font-size: 16px;
  }
  .page-header li{
    margin: 0 5px;
  }
  .long-text-box{
    min-width: auto;
  }
  .three-dot{
    display: block;
    width: 60px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .min-height {
    max-height: unset !important;
  }
  .banner-image{
    height: 47vw;
  }
  .navmenu-wrap{
    position: fixed;
    left: 0;
    right: auto;
    width: 90%;
    max-width: 300px;
    top: 0;
    bottom: 0;
    transition: all 0.5s ease;
    transform: translateX(-101%);
    padding: 30px 15px !important;
  }
  .navmenu-wrap.open{
    transform: translateX(0);
  }
  .navmenu-wrap.open ~ .overlay{
    opacity: 1;
    pointer-events: all;
  }
  .overlay{
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
  }
}
.no-border {
  border: 1px solid #dee2e6 !important;
  color: #FFFFFF !important;
  
}
.password-input .input-group-text {
  background-color: transparent;
 
  padding: 0;
}
.input-group-text {
  align-items: center;
  background-color: #e9ecef;
  border: 1px solid #3e3e3e;
  border-radius: 0;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  padding: 0.625rem 1.25rem;
  text-align: center;
  white-space: nowrap;
}

.pass_img {
  max-width: 30px;
  max-height: 30px;
}
.max-387 {
  max-width: 387px;
}
.border {
  border: 1px solid black!important;
}
.pb-11 {
  padding-bottom: 2rem!important;
}
.pt-14 {
  padding-top: 3.5rem!important;
}
.px-11 {
  padding-left: 2rem!important;
  padding-right: 2rem!important;
}
.no_padding {
  padding: 0.625rem 1.25rem !important;
  font-size: 1rem !important;
    font-weight: 400 !important;
}
.loginpage .form-control {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 3.125rem!important;
  height: 41px;
}
.password-input .input-group-text {
  background-color: transparent;
  border: none;
  padding: 0;
}
.input-group-text {
  align-items: center;
  background-color: #e9ecef;
  border: 1px solid #3e3e3e;
  border-radius: 0;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  padding: 0.625rem 1.25rem;
  text-align: center;
  white-space: nowrap;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form-data .password-input, .password-input {
  position: relative;
}
.password-input img {
  
  cursor: pointer;
  font-size: 19px;
  position: absolute;
  right: 13px;
  top: 6px;
  z-index: 9;
}
.no_height {
  height: auto !important;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
.blink-wrap{
  top: 20px;
  right: 20px;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.ck.ck-editor__main>.ck-editor__editable
 {
   background: #191919 !important;
 }
 .ck.ck-toolbar {
  background: #191919 !important;
 }
 .ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset, .ck.ck-reset_all {
  color: white !important;
 }
 .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover {
  background: #191919 !important;
 }
 .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
  background: #191919 !important;
 }
 .ck.ck-dropdown__panel {
  background: #191919!important;
  color: white !important;
 }

 .ck-editor__editable:not(.ck-editor__nested-editable) { 
  min-height: 150px;
}
.progress-bar {
  background: #7015FF !important;
}




.ck.ck-list{
  background: #3E3E3E!important;
}
.ck {
  &.ck-editor{
    &:focus-visible{
      outline: none !important;
    }
  }
}
.ck.ck-editor__main > .ck-editor__editable:focus-visible:focus{
  box-shadow: none !important;
}


.ck .ck-content{
  &:focus-visible{
    outline: none !important;
  }
}


.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1056;
}
.spin{
  height:auto;
  width:auto;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
}
.spin span{
  height:156px;
  width:156px;
  border:1px solid #FF0000;
  display:block;
  border-radius:50%;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  animation:spin 1500ms ease-in-out infinite;
}
.spin .one{
  animation-delay:500ms;
  animation:spin 2000ms ease-in-out infinite;
}
.spin .two{
  animation:spin 1700ms ease-in-out infinite;
  animation-delay:300ms;
  border:10px solid #FF0000;
}
.spin .two{
  animation:spin 2000ms ease-in-out infinite;
  border-color:#FF0000;
}
.page-dark footer{
  filter: invert(1);
}

@keyframes spin{
  0%{
      transform:scale(0);
      opacity:1;
  }
  100%{
      transform:scale(1.5);
      opacity:0;
  }
}
@keyframes hide{
  0%{
      opacity:0;
  }
  10%{
      opacity:1;
  }
  100%{
      opacity:1;
  }
}
.bg-black {
  background-color: #191919 !important;
}

.terms_style {
  color: #FFF;
text-align: center;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.5;

}
.terms_style_mobile {
  color: #FFF;
text-align: center;
font-family: Roboto;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.5;

}
.custome_a {
  color: #191919;
}
